<template>
    <div class="sign-in">

        <a-row type="flex" :gutter="[24, 24]" justify="space-around" align="middle" class="row-main">
            <!-- Sign Up Form Column -->
            <a-col :span="24" :md="{ span: 14, offset: 2 }" :lg="10" :xl="6" class="col-form mr-auto">
                <h4 class="mb-15">Sign In</h4>
                <p class="text-muted">Enter your username and password to login</p>
                <p v-if="isError" class="error">Sorry, but you submitted the wrong username or password :(</p>
                <!-- Sign Up Form -->
                <a-form id="components-form-demo-normal-login" :form="form" class="login-form" @submit="handleSubmit"
                    :hideRequiredMark="true">
                    <!-- <a-form-item class="mb-10" label="Name" :colon="false">
						<a-input 
						v-decorator="[
						'name',
						{ rules: [{ required: true, message: 'Please input your name!' }] },
						]" placeholder="Name" />
					</a-form-item> -->
                    <a-form-item class="mb-10" label="Email" :colon="false">
                        <a-input v-decorator="[
                            'email',
                            { rules: [{ required: true, message: 'Please input your email!' }] },
                        ]" placeholder="Username" />
                    </a-form-item>
                    <a-form-item class="mb-5" label="Password" :colon="false">
                        <a-input v-decorator="[
                            'password',
                            { rules: [{ required: true, message: 'Please input your password!' }] },
                        ]" type="password" placeholder="Password" />
                    </a-form-item>
                    <!-- <a-form-item class="mb-10">
						<a-checkbox
							v-decorator="[
							'remember',
							{
								valuePropName: 'checked',
								initialValue: true,
							},
							]"
						>
							I agree to the <a href="#" class="font-bold text-dark">Terms and Conditions</a>
						</a-checkbox>
					</a-form-item> -->
                    <a-form-item>
                        <a-button type="primary" block html-type="submit" class="login-form-button">
                            SIGN IN
                        </a-button>
                    </a-form-item>
                </a-form>
                <!-- / Sign Up Form -->

                <!-- <p class="font-semibold text-muted text-center">Already have an account? <router-link to="/sign-in" class="font-bold text-dark">Sign Up</router-link></p> -->
            </a-col>
            <!-- / Sign Up Form Column -->

            <!-- Sign Up Image Column -->
            <a-col :span="24" :md="12" :lg="12" :xl="12" class="col-img">
                <div>
                    <div class="img">
                        <img src="images/info-rocket-ill.png" alt="rocket">
                    </div>
                </div>
            </a-col>
            <!-- / Sign Up Image Column -->

        </a-row>

    </div>
</template>

<script>
import router from "../../../router/index.js";
import VueCookies from 'vue-cookies';
import axios from 'axios';

export default ({
    data() {
        return {
            form: this.$form.createForm(this, { name: 'signin_illustration' }),
            isError: false,
        }
    },
    methods: {
        handleSubmit(e) {
            e.preventDefault();
            this.form.validateFields(async (err, values) => {
                if (!err) {
                    try {
                        this.loading = true;
                        // Make API request to /admin/login
                        const response = await axios.post(`${process.env.VUE_APP_BASE_URL}/admin/login`, {
                            email: values.email,
                            password: values.password,
                        });

                        // Handle success response
                        if (response.data.code == "SUCCESS") {
                            const { access_token } = response.data.data;
                            VueCookies.set('access_token', access_token, "24h");

                            // Redirect to the user list page
                            router.push({ name: "Reports" });
                        } else {
                            this.isError = true;
                        }
                    } catch (error) {
                        // Handle error response
                        this.isError = true;
                        console.error("Login failed:", error.response?.data || error.message);
                    } finally {
                        this.loading = false;
                    }
                }
            });
        },
    },
})

</script>

<style lang="scss">
.error {
    color: #ff4d4f;
    font-weight: bold;
    margin-bottom: 10px;
}
</style>
